<script setup lang="ts">
import { GoBackButton } from '@/components/ui'
import { navigationPaths } from '@/constants'
import { createMfxApp, Core } from '@visiontree/mfx-auto-renderer'
import '@visiontree/mfx-auto-renderer/style.css'
import { onMounted } from 'vue'
import { validateQuestionnaire } from '@visiontree/fhir-validator'

const initAutoRenderer = () => {
  const formData = window.localStorage.getItem('formData') || '{}'
  const sources = validateQuestionnaire(JSON.parse(formData))
  sources['url'] = 'http://mint-apm1/fhir/Questionnaire/builderpreview'
  const launchConfig = {
    logLevel: 'debug',
    forms: {
      type: 'json',
      sources: [sources],
    },
  }

  Core.loadConfig(launchConfig)

  createMfxApp().mount('#previewApp')
}

onMounted(() => {
  initAutoRenderer()
})
</script>

<template>
  <GoBackButton :to-path="navigationPaths.builder" />
  <div
    id="previewApp"
    class="h-screen bg-bl-background-color text-bl-text-color dark:text-bl-dk-text-color dark:bg-bl-dk-background-color"
  ></div>
</template>
